<template lang="pug">
.faq-page
	h1 Frequently Asked Questions
</template>

<script>
export default {
	name: "Faq"
}
</script>

<style lang="scss" scoped>
.faq-page {
	h1 {
		text-align: center;
	}
}
</style>
